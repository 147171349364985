'use strict'

###*
 # @ngdoc service
 # @name ReportConfigurationManager.factory:ReportConfigurationManager

 # @description

###
angular
  .module 'mundoReporting'
  .factory 'ReportConfigurationManager', [
    'MundoDefaultManager'
    '$q'
    'Restangular'
    (
      MundoDefaultManager
      $q
      Restangular
    ) ->

      Restangular.service('reports/configurations')
  ]
